import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const Toast = ({ content, hideToast, isToastPopped }) => {
  return (
    <div className={styles.container}>
      <div
        className={`${styles.inner} ${
          isToastPopped ? styles.visible : styles.hidden
        }`}
      >
        <div
          className={styles.closeButton}
          onClick={hideToast}
          role="button"
          tabIndex={0}
          onKeyPress={({ key }) => {
            if (key === "Escape" || key === "Enter") hideToast();
          }}
        >
          <span className="icon icon-cross" />
        </div>
        {content}
      </div>
    </div>
  );
};

Toast.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideToast: PropTypes.func.isRequired,
  isToastPopped: PropTypes.bool.isRequired,
};
Toast.defaultProps = { content: undefined };

export default Toast;
