/* eslint-disable graphql/template-strings */
import { ApolloError, gql } from "@apollo/client";
import { useState, useEffect } from "react";
import { initializeApollo } from "init/apolloClient";
import { identify } from "util/analytics";
import { currentUserQuery_currentUser } from "./types/currentUserQuery";

export const CURRENT_USER_QUERY = gql`
  query currentUserQuery {
    currentUser {
      id
      uuid
      email
      phone
      isAdmin
      lastName
      isRenter
      isBroker
      firstName
      e164Phone
      isVerified
      companyName
      displayName
      hasPassword
      salesforceId
      featureFlags
      profilePicURL
      completedUserTasks {
        id
        task
      }
      confirmed
      cityOfInterest
      unconfirmedEmail
      tours {
        id
        date
      }
      latestCampaign {
        id
        uuid
        listings {
          id
        }
        currentActiveRequirements(setByUser: true) {
          id
          neighborhoods {
            id
            name
          }
          minPsf
          maxPsf
          minSquareFeet
          maxSquareFeet
          minMonthlyBudget
          maxMonthlyBudget
          numberOfEmployees
          minSqft: minSquareFeet
          maxSqft: maxSquareFeet
          maxPricePerSquareFoot: maxPsf
          minPricePerSquareFoot: minPsf
          maxMonthlyPrice: maxMonthlyBudget
          minMonthlyPrice: minMonthlyBudget
          leaseTerm
          areas {
            id
            externalId
            name
          }
          market {
            id
            name
            externalId
            position {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

interface IState {
  loading: boolean;
  error: ApolloError | undefined | null;
  currentUser?: currentUserQuery_currentUser | null;
}

/*
 * Checks user exists in cache and returns if existing, otherwise a query will be made to squapi
 */
const useCurrentUser = () => {
  const apolloClient = initializeApollo();

  const refreshCurrentUser = async () =>
    apolloClient.query({
      query: CURRENT_USER_QUERY,
      fetchPolicy: "network-only",
    });

  const login = async () => {
    const { data: CUData } = await refreshCurrentUser();
    if (CUData)
      identify(CUData.uuid, { email: CUData.email, name: CUData.displayName });
  };

  const [currentUserQuery, setCurrentUserQuery] = useState<IState>({
    loading: true,
    currentUser: undefined,
    error: undefined,
  });

  useEffect(() => {
    async function watchCurrentUser() {
      /*
       * Watches for changes to currentUser on apollo cache.
       * Because apolloClient is on the node server and on the client,
       * reactive variables don't work as expected according to the apollo documentation.
       * Subscribing to the currentUser on cache is the approach implemented below
       */
      const observableQuery = apolloClient
        .watchQuery({
          query: CURRENT_USER_QUERY,
          fetchPolicy: "cache-only",
        })
        .subscribe({
          error: (error: ApolloError) =>
            setCurrentUserQuery({ error, loading: false, currentUser: null }),
          next: (res: {
            data: { currentUser: currentUserQuery_currentUser };
            loading: boolean;
          }) =>
            setCurrentUserQuery({
              currentUser: res?.data?.currentUser,
              loading: res.loading,
              error: undefined,
            }),
        });
      /*
       * Rids of cache subscription on unmount of this hook
       */
      return function cleanup() {
        observableQuery.cleanup();
      };
    }
    watchCurrentUser();
    // disabling because empty array is intended for component did mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...currentUserQuery, refreshCurrentUser, login };
};

export default useCurrentUser;
