import { useQuery } from "@apollo/client";
import { CURRENT_USER_QUERY } from "hooks/user";
import { PropsWithChildren } from "react";

const CurrentUserProvider = ({ children }: PropsWithChildren<{}>) => {
  const { loading, error } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-first",
  });
  if (loading) return <></>;
  if (error) throw error;
  return <>{children}</>;
};

export default CurrentUserProvider;
