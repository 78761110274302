import React, { useCallback, useEffect, useState, createContext } from "react";
import PropTypes from "prop-types";
import Toast from "components/Toast";

const ToastContext = createContext();

export default ToastContext;

export function ToastContextProvider({ children }) {
  const [toast, setToast] = useState(undefined);
  const [isToastPopped, setIsToastPopped] = useState(false);
  const [timeoutLength, setTimeoutLength] = useState(3000);

  const showToast = useCallback(
    (content, length = 3000) => {
      setTimeoutLength(length);
      setToast(content);
      setIsToastPopped(true);
    },
    [setIsToastPopped, setToast]
  );

  const hideToast = useCallback(() => {
    setToast(undefined);
    setIsToastPopped(false);
  }, [setIsToastPopped, setToast]);

  useEffect(() => {
    if (toast) {
      const timer = setTimeout(() => {
        setToast(undefined);
        setIsToastPopped(false);
      }, timeoutLength);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [toast, hideToast, timeoutLength]);

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      <Toast
        content={toast}
        hideToast={hideToast}
        isToastPopped={isToastPopped}
      />
      {children}
    </ToastContext.Provider>
  );
}

ToastContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
