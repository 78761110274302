export function setCookie(name: string, value: string, days: number) {
  if (typeof document !== "undefined") {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}`;
  }
}

export function getCookie(name: string) {
  const cookie = typeof document !== "undefined" ? document.cookie : "";
  const value = cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  return value ? value.pop() : "";
}

export function parseCookies(cookieString = "") {
  return cookieString
    .split(";")
    .map((kv) => kv.trim().split("="))
    .filter((kv) => kv.length === 2)
    .reduce((acc, [key, val]) => {
      const strippedVal = val[0] === '"' ? val.slice(1, -1) : val;
      return Object.assign(acc, { [key]: strippedVal });
    }, {});
}

export function setServerIdCookie(value: string) {
  if (typeof document !== "undefined") {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 50);
    document.cookie = `SERVERID=${value}; expires=${date.toUTCString()}; path=/;`;
  }
}
