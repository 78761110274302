export const LEASE_TERMS = {
  LESS_THAN_ONE_YEAR: "< 1 Year",
  ONE_TO_TWO_YEARS: "1-2 Years",
  THREE_TO_FIVE_YEARS: "3-5 Years",
  MORE_THAN_FIVE_YEARS: "5+ Years",
  FLEXIBLE: "Flexible",
};

export const PIVOT_DESK_LEASE_TERMS = {
  ONE_TO_THREE_MONTHS: "1-3 Months",
  FOUR_TO_SIX_MONTHS: "4-6 Months",
  SEVEN_TO_TWELVE_MONTHS: "7-12 Months",
  MORE_THAN_TWELVE_MONTHS: "12+ Months",
};

export const MOVE_IN_DATES = {
  LESS_THAN_THREE_MONTHS: "< 3 Months",
  THREE_TO_SIX_MONTHS: "3-6 Months",
  SEVEN_TO_NINE_MONTHS: "7-9 Months",
  TEN_TO_TWELVE_MONTHS: "10-12 Months",
  MORE_THAN_TWELVE_MONTHS: "12+ Months",
  FLEXIBLE: "Flexible",
};

export const LISTING_STATUS = {
  UNAVAILABLE: "Unavailable",
  VETTING: "Vetting",
  AVAILABLE: "Available",
  OFFERS_IN: "Offers In",
  SF_OFFERS_IN: "SF Offer In",
  LEASE_OUT: "Lease Out",
  SF_LEASE_OUT: "SF Lease Out",
};

export const LEASE_TYPE = {
  DIRECT: "Direct Lease",
  SUBLEASE: "Sublease",
  LICENSE_AGREEMENT: "License Agreement",
};

export const NATURAL_LIGHT = {
  NONE: "None",
  POOR: "Poor",
  GOOD: "Good",
  VERY_GOOD: "Very Good",
  EXCELLENT: "Excellent",
};

export const FLOOR_MATERIAL = {
  CONCRETE: "Concrete",
  HARDWOOD: "Hardwood",
  CARPET: "Carpet",
};

export const PROPERTY_TYPES = [
  "office-space",
  "retail-space",
  "industrial-space",
];

export const BUILDING_PATH_REGEX =
  "/:state([A-Za-z]{2})/:city/:address(\\d+.+)";
export const SEARCH_PATH_REGEX =
  "/:state([A-Za-z]{2})/:city/:neighborhood(\\D+[^/]+)?/:propertyType([A-Za-z]+-space)?/:map(^map$)?";

export const BUILDING_CONTACT_CONTACT_TYPES = {
  BUILDING_AGENT: "Building Agent",
  LISTING_DEPARTMENT: "Listing Department",
  LANDLORD: "Landlord",
  UNKNOWN: "Unknown",
};

export const BUILDING_CONTACT_PHONE_TYPES = {
  personal: "Personal Office Number",
  shared: "Shared Office Number",
};

export const SPACE_KIND_TYPES = {
  OPEN_WORKSPACE: "OPEN_WORKSPACE",
  PRIVATE_OFFICE: "PRIVATE_OFFICE",
};

export const MATCH_TYPES = {
  MATCH: "MATCH",
  NOT_MATCH: "NOT_MATCH",
};

export const MUTATION_NAMES = {
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
};

export const QUERY_NAMES = {
  GET_CURRENT_USER: "GET_CURRENT_USER",
};

export const EMAIL_REGEX = "^([^@\\s]+)@((?:[-a-z0-9]+\\.)+[a-z]{2,})$";
