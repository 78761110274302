const SITE_NAME = "SquareFoot";
const SITE_DESCRIPTION =
  "We’re a new kind of commercial real estate company. We help growing businesses win at finding their next office space, providing flexibility, transparency, and a stress-free process along the way.";

const CONTACT_ADDRESS = "115 W 30th Street, Suite 900";
const CONTACT_CITY = "New York City";
const CONTACT_STATE = "NY";
const CONTACT_ZIP = "10001";
const CONTACT_NUMBER_DISPLAY = "(917) 994-1062";
const CONTACT_NUMBER_VALUE = "+9179941062";
const SQUAREFOOT_HQ_COORDS = { lat: 40.741047, lng: -73.992917 };

const SM_FACEBOOK = "https://www.facebook.com/TheSquareFoot";
const SM_TWITTER = "https://twitter.com/thesqft";
const SM_LINKEDIN = "https://www.linkedin.com/company/2220452";
const SM_INSTAGRAM = "https://www.instagram.com/squarefoot_/";

const APP_STORE_ID = "1124552660";
const APP_STORE_URL =
  "https://itunes.apple.com/us/app/squarefoot-mobile/id1124552660?mt=8";

const THEME_COLOR = "#ffffff";
// Nope, copy the stylesheet from icomoon and paste it into /styles/icomoon.css. This avoids getting dinged in lighthouse for having a blocking resource
// (If you loaded this via a link file, you need to wait for the CDN CSS to load which then calls the font files. Not good for perf.)
// ICOMOON_CSS:
//   "https://d1azc1qln24ryf.cloudfront.net/97723/miyagi/style-cf.css?nnog0l",

const GOOGLE_MAPS_API_KEY = "AIzaSyC2_phtcUn04WIqZGQIhCFZSUPPsWezev0";
const GOOGLE_MAPS_STATIC_MAP_API_KEY =
  "AIzaSyB_HUAJLjmcIKcN-hiYb-tspTzRVyxEqoI";
const GOOGLE_MAPS_STATIC_MAP_MARKER_URL =
  "https://s3.amazonaws.com/thesquarefoot-assets-production/static-maps/pin@3x.png";
const GOOGLE_MAPS_STATIC_MAP_MARKER_URL_CLIENT_SEARCH =
  "https://s3.amazonaws.com/thesquarefoot-assets-production/static-maps/pin@2x.png";

const FB_APP_ID = "496698383783212";
const SEGMENT_SEO_PAGE_WRITE_KEY = "hj3cqxVSYIb7pEgN9BJgN6BxC8zN0Czs";
const MATTERPORT_API_KEY = "addd8e0221ca4a31bb992f8ef8b674b1";
const MATTERPORT_SDK_VERSION = "3.0";
const PORT = 4000;

//   // ENV vars that need to be included at build time (Travis)
//   FIREBASE_CONFIG: {
//     apiKey: process.env.FIREBASE_API_KEY,
//     authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//     databaseURL: process.env.FIREBASE_DATABASE_URL,
//     projectId: process.env.FIREBASE_PROJECT_ID,
//     storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.FIREBASE_APP_ID,
//   },
//   ASSET_PATH: process.env.ASSET_PATH,
const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
//   API_URL: process.env.API_URL,
const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

// ENV vars that need to be set both at build (Travis) and run time (via ECS Task Def)
const NEXT_PUBLIC_AIRBRAKE_PROJECT_ID = Number(
  process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_ID
);
const NEXT_PUBLIC_AIRBRAKE_PROJECT_KEY =
  process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_KEY || "";
const { NEXT_PUBLIC_AIRBRAKE_ENV } = process.env;
//   STUB_TWILIO: process.env.STUB_TWILIO,

const { NEXT_PUBLIC_FOO_VITALS_URL } = process.env;
const { NEXT_PUBLIC_FOO_ACCOUNT_ID } = process.env;

export {
  PORT,
  BASE_URL,
  SITE_NAME,
  FB_APP_ID,
  SM_TWITTER,
  CONTACT_ZIP,
  SM_FACEBOOK,
  SM_LINKEDIN,
  THEME_COLOR,
  CONTACT_CITY,
  SM_INSTAGRAM,
  APP_STORE_ID,
  CONTACT_STATE,
  APP_STORE_URL,
  CONTACT_ADDRESS,
  SITE_DESCRIPTION,
  SEGMENT_WRITE_KEY,
  MATTERPORT_API_KEY,
  GOOGLE_MAPS_API_KEY,
  CONTACT_NUMBER_VALUE,
  SQUAREFOOT_HQ_COORDS,
  CONTACT_NUMBER_DISPLAY,
  MATTERPORT_SDK_VERSION,
  NEXT_PUBLIC_AIRBRAKE_ENV,
  NEXT_PUBLIC_FOO_ACCOUNT_ID,
  NEXT_PUBLIC_FOO_VITALS_URL,
  SEGMENT_SEO_PAGE_WRITE_KEY,
  GOOGLE_MAPS_STATIC_MAP_API_KEY,
  NEXT_PUBLIC_AIRBRAKE_PROJECT_ID,
  NEXT_PUBLIC_AIRBRAKE_PROJECT_KEY,
  GOOGLE_MAPS_STATIC_MAP_MARKER_URL,
  GOOGLE_MAPS_STATIC_MAP_MARKER_URL_CLIENT_SEARCH,
};
